import Network from '@/services/network'
import { ref } from 'vue'

export default function handleReport() {
    const network = new Network;
    const reportLoading = ref(false)

    const fetchDaybook = (query) => {
      return network.api('get', '/reports/day-book'+query);
    }

    const getLedger = (data) => {
      return network.api('get', '/reports/general-ledger/'+data.headId + data.query);
    }

    const getLedgerOld = (data) => {
      return network.api('get', '/reports/general-ledger-old/'+data.headId + data.query);
    }

    const getPartyLedger = (data) => {
      return network.api('get', '/reports/ledger/party'+ data.query);
    }

    const getPartyLedgerSummary = (data) => {
      return network.api('get', '/reports/party-ledger/summary'+ data.query);
    }

    const getTrialBalance = (query) => {
      return network.api('get', '/accounting/trial-balance'+ query);
    }

    const fetchAccountHeads = (companyId) => {
      return network.api('get', '/account-heads?company_id='+companyId);
    }

    const fetchHome = () => { //status, voucher, account_type
        return network.api('get', '/get/home');
    }

    const fetchReconciliationList = (query) => {
        return network.api('get', '/reports/bank-reconciliation'+query);
    }

    const fetchReconciliationNumber = query => {
        return network.api('get', '/reports/bank-reconciliation/generate-reconciliation' + query);
    }

    const fetchLastReconciliationDate = query => {
        return network.api('get', '/reports/bank-reconciliation/last-reconciliation-date' + query);
    }

    const fetchBankBookBalance = query => {
        return network.api('get', '/reports/bank-reconciliation/bank-book-balance' + query);
    }

    const fetchSpecificReconciliationData = (data) => {
        return network.api('get', '/reports/bank-reconciliation/'+data.headId + data.query);
    }

    const fetchReconciliationDataForPrint = (reconciliationRef, query) => {
        return network.api('get', '/reports/bank-reconciliation/get-print-data/'+reconciliationRef + query);
    }

    const fetchReconciliationDataByRef  = (reconciliationRef, query) => {
        return network.api('get', '/reports/bank-reconciliation/bank-reconciliation-by-ref/'+reconciliationRef + query);
    }

    const createReconciliation = (data) => {
        return network.api('post', '/reports/bank-reconciliation', data);
    }

    const updateReconciliation = (reconciliationRef, data) => {
        return network.api('post', '/reports/bank-reconciliation/'+reconciliationRef, data);
    }


    return {
        fetchDaybook,
        getLedger,
        getLedgerOld,
        fetchAccountHeads,
        getTrialBalance,
        fetchHome,
        fetchReconciliationList,
        fetchReconciliationNumber,
        fetchSpecificReconciliationData,
        fetchReconciliationDataForPrint,
        fetchReconciliationDataByRef,
        fetchLastReconciliationDate,
        fetchBankBookBalance,
        createReconciliation,
        updateReconciliation,
        getPartyLedger,
        getPartyLedgerSummary,
        reportLoading
    }
}
