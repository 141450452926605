<template>
  <div class="card" style="min-height: 200px">
    <vue3-html2pdf
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      pdf-content-width="100%"
      :htmlToPdfOptions="htmlToPdfOptions"
      ref="html2Pdf"
    >
      <template  v-slot:pdf-content>
        <div class="card-body">
          <div class="p-1">
            <div class="row">
              <div class="col-12 col-lg-6 mt-2">
                <p class="h4"><strong>{{ company?.name ? company.name : "" }}</strong></p>
                <p class="h4"><strong>Bank Reconciliation Statement</strong></p>
                <p class="h4">Account Name : {{ reconciliationData.account_name}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <p class="h4">Reconciliation Date: <strong>{{ reconciliationData.reconciliation_date }}</strong></p>
                <p class="h4">From Date: <strong>{{ reconciliationData.opening_date }}</strong></p>
                <p class="h4">To Date: <strong>{{ reconciliationData.closing_date }}</strong></p>
              </div>
              <div class="col-6">
                <p class="h4">Reconciliation Ref: <strong>{{ reconciliationData.reconciliation_ref }}</strong></p>
              </div>
            </div>
          </div>

          <div class="col-12 px-2">
            <hr>
            <p class="h4 m-0 text-center">
              <strong>Reconciliation Summary</strong>
            </p>
            <hr>
          </div>
          <div class="row pb-0 p-2">
            <div class="col-8">
              <p class="h4">
                Balance as per bank book
              </p>
            </div>
            <div class="col-4 text-end">
              <p class="h4"><strong>{{ commaFormat(reconciliationData.bank_book_balance) }}</strong></p>
            </div>
          </div>
          <div class="row pb-0 pt-0 p-2">
            <div class="col-8">
              <p class="h4">
                Balance as per bank statement
              </p>
            </div>
            <div class="col-4 text-end">
              <p class="h4"><strong>{{ commaFormat(reconciliationData.bank_statement_balance) }}</strong></p>
            </div>
          </div>
          <div class="row pb-0 pt-0 p-2">
            <div class="col-8">
              <p class="h4">
                Difference
              </p>
            </div>
            <div class="col-4 text-end">
              <p class="h4"><strong>{{ commaFormat(reconciliationData.difference) }}</strong></p>
            </div>
          </div>

          <div class="col-12 px-2">
            <hr>
            <p class="h4 m-0 text-center">
              <strong>Reconciliation Details</strong>
            </p>
            <hr>
          </div>
          <div class="row p-2">
            <div class="col-8">
              <p class="h4">
                Balance as per bank statement
              </p>
            </div>
            <div class="col-4 text-end">
              <p class="h4"><strong>{{ commaFormat(reconciliationData.bank_statement_balance) }}</strong></p>
            </div>
            <hr>
          </div>

          <div class="col-12 px-2">
            <p class="h4 m-0">
              Add: Uncredited deposit (Receipts recorded in my account, but not recorded by Bank)
            </p>
            <hr>
          </div>
          <div class="row pt-0 p-2">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>Voucher Date</th>
                <th>Payment ref</th>
                <th>Payment ref Date</th>
                <th>Party Name</th>
                <th class="text-end">Amount</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in receipts">
                <td>{{ item.date }}</td>
                <td>{{ item.payment_ref }}</td>
                <td>{{ item.payment_ref_date }}</td>
                <td>{{ item.party }}</td>
                <td class="text-right">{{ commaFormat(item.amount) }}</td>
              </tr>
              <tr v-if="receipts.length">
                <th colspan="4"><strong>Total</strong></th>
                <td class="text-right"> <strong>{{ commaFormat(reconciliationData.receipt_total) }}</strong></td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="col-12 px-2">
            <hr>
            <p class="h4 m-0">
              Less : Unrepresented cheque (Payments recorded in my account, but not recorded by Bank)
            </p>
            <hr>
          </div>
          <div class="row pt-0 p-2">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>Voucher Date</th>
                <th>Payment ref</th>
                <th>Payment ref Date</th>
                <th>Party Name</th>
                <th class="text-end">Amount</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in payments">
                <td>{{ item.date }}</td>
                <td>{{ item.payment_ref }}</td>
                <td>{{ item.payment_ref_date }}</td>
                <td>{{ item.party }}</td>
                <td class="text-right">{{ commaFormat(item.amount) }}</td>
              </tr>
              <tr v-if="payments.length">
                <th colspan="4"><strong>Total</strong></th>
                <td class="text-right"> <strong>{{ commaFormat(reconciliationData.payment_total) }}</strong></td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="col-12 px-2">
            <hr>
            <div class="row">
              <div class="col-8">
                <p class="h4">
                  Total reconciliation amount
                </p>
              </div>
              <div class="col-4 text-end">
                <p class="h4"><strong>{{ commaFormat((reconciliationData.receipt_total - reconciliationData.payment_total)) }}</strong></p>
              </div>
            </div>
            <hr>
          </div>

          <div class="col-12 px-2">
            <hr>
            <div class="row">
              <div class="col-8">
                <p class="h4">
                  Reconciled balance as per bank statement
                </p>
              </div>
              <div class="col-4 text-end">
                <p class="h4"><strong>{{ commaFormat((reconciliationData.bank_statement_balance + (reconciliationData.receipt_total - reconciliationData.payment_total))) }}</strong></p>
              </div>
            </div>
            <hr>
          </div>

          <div class="col-12 px-2">
            <hr>
            <div class="row">
              <div class="col-8">
                <p class="h4">
                  Balance as per bank book (my Account)
                </p>
              </div>
              <div class="col-4 text-end">
                <p class="h4"><strong>{{ commaFormat(reconciliationData.bank_book_balance) }}</strong></p>
              </div>
            </div>
            <hr>
          </div>
        </div>
      </template>
    </vue3-html2pdf>
    <div class="card-footer">
      <button class="btn btn-primary float-end" @click="exportToPDF">Download as PDF</button>
    </div>
    <Loader v-if="loader"/>
  </div>
</template>
<script setup>
import handleCompany from "@/services/modules/company";
import Loader from "@/components/atom/LoaderComponent";
import handleReport from "@/services/modules/accountingReport";
import {onMounted, ref, inject, computed} from "vue";
import {useRoute} from "vue-router";
import Vue3Html2pdf from "vue3-html2pdf";
import figureFormatter from '@/services/utils/figureFormatter'

const company = ref({});
const loader = ref(false);
const requisition = ref({});
const html2Pdf = ref(null);
const {fetchCompanyInfo} = handleCompany();
const {fetchReconciliationDataForPrint} = handleReport();
const {commaFormat} = figureFormatter();
const route = useRoute();
const showError =  inject('showError');
const reconciliationRef = computed(() => {
  return route.query.reconciliationRef;
});
let reconciliationData = ref({
  account_name : "",
  reconciliation_ref : "",
  reconciliation_date : "",
  bank_book_balance : "",
  bank_statement_balance : "",
  difference : "",
  receipt_total : "",
  payment_total : "",
});
let receipts = ref([]);
let payments = ref([]);

const exportToPDF = () => {
  html2Pdf.value.generatePdf();
}
const htmlToPdfOptions = ref({
  margin: 0,
  filename: reconciliationRef,
  image: {
    type   : 'jpeg',
    quality: 1
  },
  enableLinks: false,
  html2canvas: {
    scale  : 2,
    useCORS: true
  },
  jsPDF: {
    unit       : 'mm',
    format     : 'a3',
    orientation: 'portrait'
  }
});

onMounted(() => {
  loader.value = true;
  const companyQuery = `?company_id=${route.params.companyId}`;
  const fetchCompany = fetchCompanyInfo(route.params.companyId);
  const fetchReconciliation = fetchReconciliationDataForPrint(reconciliationRef.value, companyQuery);
  Promise.all([
    fetchCompany.then(res => {
      company.value = res.data;
    }),
    fetchReconciliation.then(res => {
      reconciliationData.value = res.data.reconciliation_data;
      reconciliationData.value.receipt_total = res.data.total_receipt;
      reconciliationData.value.payment_total = res.data.total_payment;
      reconciliationData.value.difference = (reconciliationData.value.bank_book_balance - reconciliationData.value.bank_statement_balance);
      receipts.value = res.data.receipts;
      payments.value = res.data.payments;
    })
  ])
  .then( () => loader.value = false )
  .catch( (err) => {
    showError("Something went wrong");
    loader.value = false;
  })
});
</script>
